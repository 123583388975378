import * as Sentry from "@sentry/vue";
import { Attendee, LocalVideoTrack, Media, MediaDeviceManager, UserVideoTrack } from "@liveswitch/sdk";
import DeviceUtility from "../helpers/device";

function liveswitchSdk(){
    let _togglingCamera = false

    Media.prototype.hasActiveVideo = function(){
        console.log('has active video start', this)
        if(!this.videoTrack){
            console.log('no video track')
            // no track => active video is false
            return false
        }
        if(this.videoTrack.isStarted === false){

            console.log('no started video track')
            // not started => active video is false
            return false
        }
        if(this.videoTrack.isMuted === true){

            console.log('muted video track')
            // muted => active video is false
            return false
        }
        if(!this.videoTrack.frameSize){
            console.log('no frame')
            // muted => active video is false
            return false
        }
        if(this.videoTrack.frameSize.width <= 2){

            console.log('small video track')
            // small frame size => active video is false
            return false
        }
        return true
    }

    // represents when the join process has completed
    Attendee.prototype.hasJoinedMeeting = false
    Attendee.prototype.hasAudio = true
    Attendee.prototype.hasVideo = true

    LocalVideoTrack.prototype.setFacingMode = function(facingMode){
        this._facingMode = facingMode
    }

    let oldGetConstraints = UserVideoTrack.prototype.getConstraints
    UserVideoTrack.prototype.getConstraints = async function(){
        let results = await oldGetConstraints.call(this, arguments)
        if (this._facingMode) {
            results.facingMode = this._facingMode
        }
        return results
    } 

    UserVideoTrack.prototype.isOnFrontCamera = true

    UserVideoTrack.prototype.useNextExpectedDevice = async function(){
        if (MediaDeviceManager.shared.videoInputs.count < 2) return
        this.isOnFrontCamera ? await this.useCameraByFacingMode('environment') : await this.useCameraByFacingMode('user')
    }

    UserVideoTrack.prototype.useCameraByFacingMode = async function(facingMode){
        if (!['user', 'environment'].includes(facingMode)) throw Error(`${facingMode} must either be user or environment.`)
        if (_togglingCamera) return

        try {
            _togglingCamera = true
            this.isOnFrontCamera = facingMode === 'user'

            const supportedConstraints = window.navigator.mediaDevices.getSupportedConstraints();
            if (!Object.keys(supportedConstraints).includes('facingMode')) {
                throw Error('This device does not support the facing mode constraint.')
            }

            this.setFacingMode(facingMode)
            await this.replaceStream()
        } catch (e) {
            Sentry.captureException(e)
            const label = facingMode === 'user' ? 'front' : 'back'
            await this.useCameraByLabel(label)
        } finally {
            _togglingCamera = false
        }  
    }

    UserVideoTrack.prototype.useCameraByLabel = async function(label){
        if (!['front', 'back'].includes(label)) throw Error(`${label} must either be front or back.`)

        this.isOnFrontCamera = label === 'front'
        const labeledCameras = MediaDeviceManager.shared.videoInputs.filter(x => x.label && x.label.toLocaleLowerCase().indexOf(label) > -1)
        
        if(labeledCameras.length > 0){
            const cameraId = labeledCameras[0].id
            await this.setDevice(cameraId)
        } else {
            Sentry.captureMessage('Could not use device labels to toggle camera.')
            await this.guessFrontOrBackCameraByIndex(label)
        }          
    }

    UserVideoTrack.prototype.guessFrontOrBackCameraByIndex = async function(label){
        if (!['front', 'back'].includes(label)) throw Error(`${label} must either be front or back.`)

        let index = 0
     
        if (label === 'back') {
            index = 1

            if (DeviceUtility.isAndroid && MediaDeviceManager.shared.videoInputs.count > 2) { 
                // android with more than 2 cameras - assume that the 3rd camera is the first back camera..yikes
                index = 2
            } 
        }

        const cameraId = MediaDeviceManager.shared.videoInputs[index].id
        await this.setDevice(cameraId)
    }
}

export { liveswitchSdk }