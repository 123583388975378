import Bowser from "bowser";

class DeviceUtility {     
    static get browser(){
        const navigator = window.navigator;
        if (!navigator) return 'unknown'
        const parser = Bowser.getParser(window.navigator.userAgent);
        return parser.getBrowserName(); 
    }

    static get isAndroid(){
        const parser = Bowser.getParser(window.navigator.userAgent);
        return parser.getOSName(true) === 'android'
    }

    static get isDesktop(){
        return this.getDeviceType() === 'desktop'
    }

    static get isFirefox(){
        return this.browser === 'Firefox'
    }

    static get isMobile(){
        return this.getDeviceType() === 'mobile'
    }

    static get isSafari(){
        return this.browser === 'Safari'
    }

    static getDeviceType = function(){
        const navigator = window.navigator;
        if (!navigator) return 'unknown';

        const result = Bowser.parse(window.navigator.userAgent)
        if((result.platform.model && result.platform.model.toLocaleLowerCase() === 'ipad') ||
            window.matchMedia('only screen and (min-device-width: 768px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2)').matches) return 'tablet'

        return !result.platform.type || result.platform.type === undefined ? 'unknown' : result.platform.type.toLocaleLowerCase()
    }
}
export default DeviceUtility