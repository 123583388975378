import { createRouter, createWebHistory } from 'vue-router' 
import { beaconStore } from '@/store/beacon';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/chat',
      name: 'chat',
      component: () => import('@/views/Window.vue'),
      children: [
        {
          path: '/chat',
          name: 'userinformation',
          component: () => import('@/layouts/UserInformation.vue')
        },
        {
          path: '/searching',
          name: 'searching',
          component: () => import('@/layouts/Searching.vue')
        },
        {
          path: 'incall',
          name: 'incall',
          component: () => import('@/layouts/InCall.vue')
        },
        {
          path: 'missed',
          name: 'missed',
          component: () => import('@/layouts/Missed.vue')
        },
        {
          path: 'ended',
          name: 'ended',
          component: () => import('@/layouts/Ended.vue')
        }
      ]
    },
    {
      path: '/lite',
      name: 'lite',
      component: () => import('@/views/Lite.vue')
    },
    {
      path: '/',
      name: 'bubble',
      component: () => import('@/views/Bubble.vue')
    },
    {
      path: '/echo',
      name: 'echo',
      component: () => import('@/views/Test.vue')
    }
  ]
})

router.beforeEach((to, from) => {
  const useBeaconStore = beaconStore();
  if (Object.keys(to.query).length) {
    if (to.query.websiteCodename) {
      useBeaconStore.websiteCodename = to.query.websiteCodename;
    }
    if (to.query.mode) {
      useBeaconStore.mode = to.query.mode
    }
    if (to.query.bgcolor) {
      useBeaconStore.beaconBGColor = to.query.bgcolor;
    }
  }
  return true;
});

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})

export default router
