import * as Sentry from '@sentry/vue';
import { ReportingObserver as ReportingObserverIntegration, ExtraErrorData as ExtraErrorDataIntegration, CaptureConsole as CaptureConsoleIntegration,  HttpClient as HttpClientIntegration } from "@sentry/integrations";

export function loadSentry (app, router) {
    const environment = NODE_ENV;
    const release = APP_VERSION;
    const name = APP_NAME;
    const sampleRate = (NODE_ENV != "production") ? 1.0 : 0.1;
    Sentry.init({
        app,
        dsn: 'https://fd0262ce954642cf896fcf1c0d5185e0@o4504596163002368.ingest.sentry.io/4504686451621888',
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          }),
          new Sentry.Replay(),
          new ExtraErrorDataIntegration({
            // Limit of how deep the object serializer should go. Anything deeper than limit will
            // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
            // a primitive value. Defaults to 3.
            depth: 3
          }),
          new CaptureConsoleIntegration(
            {
              // array of methods that should be captured
              // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
              levels: ['warn', 'error']
            }
          ),
          new HttpClientIntegration({
            // This array can contain tuples of `[begin, end]` (both inclusive),
            // single status codes, or a combination of both.
            // default: [[500, 599]]
            failedRequestStatusCodes: [[500, 505], 507],
      
            // This array can contain Regexes, strings, or a combination of both.
            // default: [/.*/]
            failedRequestTargets: [/.*/]
          }),
          new ReportingObserverIntegration()
        ],
        environment,
        release: `${name}@${release}`,
        sendDefaultPii: true,
        // Performance Monitoring
        tracesSampleRate: sampleRate, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: sampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
}