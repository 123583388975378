
import { defineStore } from 'pinia'
import { ref } from "vue";

export const beaconStore = defineStore('beacon', () => {
    /* Minimum config to operate for Lite users. */
    let beaconConfig = {
        beaconBGColor: '#FFF',
        position: 'right',
        showOnLoad: true,
        templateCalling: 'Calling...',
        templatePopup: 'Talk to a real person!',
        templateEnded: 'Call has ended.',
        templateMissedCall: 'No one was available, please try again later.',
        templateWelcome: 'Place a call to our team now.'
    };
    try {
        if (localStorage.getItem('beaconConfig')) {
            const local = JSON.parse(localStorage.getItem('beaconConfig'));
            let keys = Object.keys(local);
            for (let i = 0, l = keys.length; i < l; i++) {
                let key = keys[i];
                beaconConfig[key] = local[key];
            }
        }
    } catch(e) {
        console.warn("Local storage not supported.");
    }

    const queryParams = new URLSearchParams(window.location.search);
    const mode = queryParams.get("mode") || "concierge";
    let websiteCodename = queryParams.get("websiteCodename");
    const checkAvailbility = ref(mode === 'lite' ? false : true);
    const collectMissedData = ref(mode === 'lite' ? false : true);

    const beaconBGColor = ref(beaconConfig.beaconBGColor);
    const position = ref(beaconConfig.position);
    const showOnLoad = ref(beaconConfig.showOnLoad);
    const templateCalling = ref(beaconConfig.templateCalling);
    const templatePopup = ref(beaconConfig.templatePopup);
    const templateEnded = ref(beaconConfig.templateEnded);
    const templateMissedCall = ref(beaconConfig.templateMissedCall);
    const templateWelcome = ref(beaconConfig.templateWelcome);
    
    return { beaconBGColor, position, showOnLoad, templateCalling, templatePopup, templateEnded, templateMissedCall, templateWelcome, mode, checkAvailbility, websiteCodename, collectMissedData }
});